export default {
  data () {
    return {
      dataMixinSmsFields: [
        {
          label: '',
          key: 'isBlocked',
          tdClass: 'call-tabel-icon',
          thClass: 'call-tabel-icon'
        },
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th'
        },
        {
          label: 'Template Name',
          key: 'name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th'
        },
        {
          label: 'Available For',
          key: 'teams',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Created By',
          key: 'created_by_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Created at',
          key: 'created_at',
          sortable: true,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
