<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Confirm actions"
    @close="onCloseModal">
    <div v-if="computedIsActive">
      Are you sure you want to Deactivate ?
    </div>
    <div v-else>
      Activate this template?
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="!computedIsActive ? dataIconCheck : dataIconBan"
        type="success"
        @click="clickBlocked()">
        {{ computedIsActive ? 'Deactivate' : 'Activate' }}
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import iconsModal from '../../../mixins/iconsModal';
import { SMS_TEMPLATE_UPDATE } from '@/store/types/actions.types';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallSmsActiveDeactivate',
  components: {
    CallButton,
    BModal
  },
  mixins: [iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    objEvent: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconBan: faBan,
      dataModal: this.value
    };
  },
  computed: {
    computedIsActive () {
      return this.objEvent.is_active;
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async  clickBlocked () {
      await this.$store.dispatch(`sms/${SMS_TEMPLATE_UPDATE}`, {
        templateId: this.objEvent.templateId,
        params: {
          is_active: !this.computedIsActive
        },
        context: this
      });
      this.$emit('on-update');
      this.$Notify({
        title: 'Sms Template',
        message: `Successfully ${this.computedIsActive ? 'deactivated' : 'active'}`,
        type: 'success'
      });
      this.onCloseModal();
    }
  }
};
</script>
