<template>
  <div class="home">
    <div class="filter-wrapper">
      <div class="link-wrapper">
        <router-link :to="{ name: 'SmsTemplate'}">
          Templates
        </router-link>
        <router-link :to="{ name: 'SmsLogs'}">
          Logs
        </router-link>
      </div>
      <div>
        <call-pagination
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
        <call-button
          :icon="dataAddIcon"
          type="success"
          @click="clickShowAddTemplate()">
          Add Template
        </call-button>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :fields="dataMixinSmsFields"
        :items="dataSmsList"
        @sort-changed="onSortTabel"
        @row-hovered="onHover"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-unhovered="onUnHover">
        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="!data.item.is_active"
            class="tabel-icon--red"
            :icon="dataIconBan" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(name)="data">
          <call-tooltip
            placement="bottom"
            :content="data.item.body">
            <span>{{ data.item.name }}</span>
          </call-tooltip>
        </template>
        <template #cell(teams)="data">
          {{ data.item.teams | joinName }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>

        <template #cell(actions)="data">
          <call-table-sms-template-actions
            v-show="data.item.actions"
            :blocked="data.item.is_active"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
        <template #cell(outcoming_cancelled_calls_count)="data">
          <span class="danger"> {{ data.item.outcoming_cancelled_calls_count || 0 }} </span>
        </template>
      </b-table>
    </div>
    <call-modal-add-sms-template
      v-model="dataShowAddTemplateSms"
      @on-update="() => $_ajax()" />
    <call-modal-update-sms-template
      v-model="dataShowUpdateTemplateSms"
      :template-id="dataOptionTemplateId"
      @on-update="() => $_ajax()" />
    <call-sms-active-deactivate
      v-model="dataShowActionConfirmSms"
      :obj-event="dataOptionObjEvent"
      @on-update="() => $_ajax()" />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { SMS_TEMPLATE_GET_ALL } from '../../../store/types/actions.types';
import helper, { tabelCell } from '../../../service/helper';
import CallPagination from '@/components/desktop/CallPagination';
import CallButton from '@/components/common/CallButton';
import { faBan, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import smsTable from '@/mixins/table/smsTable';
import CallModalAddSmsTemplate from '@/components/common/modals/CallAddSmsTemplate';
import CallTableSmsTemplateActions from '@/components/desktop/CallTableSmsTemplateActions';
import CallModalUpdateSmsTemplate from '@/components/common/modals/CallUpdateSmsTemplate';
import CallSmsActiveDeactivate from '@/components/common/modals/CallSmsActiveDeactivate';

export default {
  name: 'PageSms',
  components: {
    CallSmsActiveDeactivate,
    CallModalUpdateSmsTemplate,
    CallTableSmsTemplateActions,
    CallModalAddSmsTemplate,
    CallButton,
    CallPagination,
    BTable
  },
  mixins: [smsTable],
  data () {
    return {
      dataAddIcon: faPlus,
      dataIconCheck: faCheck,
      dataIconBan: faBan,
      dataShowAddTemplateSms: false,
      dataShowUpdateTemplateSms: false,
      dataShowActionConfirmSms: false,
      dataOptionTemplateId: null,
      dataOptionObjEvent: {},
      dataObjPagination: {},
      dataSmsList: [],
      dataDesc: null,
      dataOrderBy: null
    };
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = tabelCell();

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'desc',
            'orderBy',
            'limit',
            'page'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ desc = this.dataDesc, orderBy = this.dataOrderBy, limit = null, page = null, reset = false } = options) => {
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const { payload: { list = [] }, misc: { paging = {} } = {} } = await this.$store.dispatch(`sms/${SMS_TEMPLATE_GET_ALL}`, {
          params: {
            desc,
            order_by: orderBy,
            limit: _limit,
            page: _page
          }
        });
        this.dataObjPagination = paging;
        this.dataSmsList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              desc,
              orderBy,
              limit: _limit,
              page: _page
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    await this.$_ajax();
  },
  methods: {
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    clickShowAddTemplate () {
      this.dataShowAddTemplateSms = true;
    },
    async  onActions ({ id: templateId, is_active, name = '' }, action) {
      switch (action) {
        case 'update':
          this.dataOptionTemplateId = templateId;
          this.dataShowUpdateTemplateSms = true;
          break;
        case 'blocked':
          this.dataOptionObjEvent = {
            templateId,
            is_active
          };
          this.dataShowActionConfirmSms = true;
          break;
      }
    }
  }
};
</script>
