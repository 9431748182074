<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Add Template"
    @close="onCloseModal">
    <div>
      <call-input
        v-model="$v.dataForm.name.$model"
        type-input="text"
        :valide="$v.dataForm.name.$error"
        float-name-label="Template Name" />
      <div
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.team_ids ? 'call-select--empty' : null]">
        <v-select
          v-model="dataForm.team_ids"
          multiple
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Avaliable For</label>
      </div>
      <div
        :class="['call-select-modal__wrapper', 'call-input', $v.dataForm.body.$error && 'error-valide']">
        <b-form-textarea
          v-model="$v.dataForm.body.$model"
          placeholder="SMS template..."
          rows="3"
          max-rows="6" />
      </div>
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataAddIcon"
        :disabled="$v.$invalid"
        type="success"
        @click="clickAddSmsTemplate()">
        Add Template
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BFormTextarea, BModal } from 'bootstrap-vue';
import { faSearch, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import CallButton from '../CallButton';
import CallInput from '../CallInput';
import { required, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { ID_ROLE_OPERATOR } from '../../../service/consts';
import { SMS_TEMPLATE_CREATE, TEAMS_GET_ALL } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import selectAllTeams from '../../../mixins/select/selectAllTeams';

export default {
  name: 'CallModalAddSmsTemplate',
  components: {
    CallInput,
    CallButton,
    BModal,
    BFormTextarea
  },
  mixins: [validationMixin, iconsModal, selectAllTeams],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataIconSearch: faSearch,
      dataIconRefresh: faSyncAlt,
      dataShowPassword: false,
      dataForm: {
        name: null,
        team_ids: [],
        body: null
      }
    };
  },
  validations: {
    dataForm: {
      name: {
        required
      },
      body: {
        required,
        maxLength: maxLength(255)
      }
    }
  },
  computed: {
    computedIsOperator () {
      return this.dataForm.role === ID_ROLE_OPERATOR;
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();
        const { payload: { list = [] } } = await this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
          params: {
            short: true
          }
        });
        this.dataSelectOptionsTeam = list.filter(item => item.is_active);
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickAddSmsTemplate () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`sms/${SMS_TEMPLATE_CREATE}`, {
          params: {
            ...this.dataForm,
            team_ids: this.dataForm.team_ids.map(item => item.id)
          },
          context: this
        });
        this.$emit('on-update');
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
